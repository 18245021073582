<template>
	<div class="article-box">
		<el-table :data="articles" style="width: 100%" v-loading="loading">
			<el-table-column prop="serial" label="序号" type="index" align="center" width="60">
				<template slot-scope="scope">
					{{ (currpage - 1) * pagesize + scope.$index + 1 }}
				</template>
			</el-table-column>
			<el-table-column prop="jbrName" label="举报人昵称" align="center"> </el-table-column>
			<el-table-column prop="title" label="文章标题" align="center"></el-table-column>
			<el-table-column prop="artName" label="文章作者" align="center"></el-table-column>
			<el-table-column prop="type" label="举报原因" align="center"></el-table-column>
			<el-table-column sortable prop="create_time" label="时间" align="center">
			</el-table-column>
			<el-table-column prop="Author" label="状态" align="center"><template slot-scope="scope">
					<div v-if="scope.row.status==0">待审核</div>
					<div v-if="scope.row.status==1">已审核</div>
					<div v-if="scope.row.status==2">已拒绝</div>
					<div v-if="scope.row.status==3">已冻结</div>
					<div v-if="scope.row.status==5">已取消</div>
					<div v-if="scope.row.status==6">待处理</div>
				</template>
			</el-table-column>
			<el-table-column label="操作" fixed="right" align="center">
				<template slot-scope="scope">
					<el-button size="mini" type="primary" plain
						@click="handleEdit(scope.$index, scope.row)">详情</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="block">
			<el-pagination @current-change="handleCurrentChange" :page-size="pagesize" background
				layout="prev, pager, next, jumper" :total="CountNum" style="margin-top: 20px">
			</el-pagination>
		</div>
		<el-dialog title="文章审核" :visible.sync="dialogFormVisible" top="3px">
			<el-form :model="article" ref="articleForm">
				<el-form-item label="标题">
					<div style="text-align: left;">
						{{article.title}}
					</div>
				</el-form-item>
				<el-form-item label="举报原因">
					<el-input v-model="article.type" autocomplete="off" style="width:500px;float:left"
						readonly></el-input>
				</el-form-item>
				<el-form-item label="举报描述">
					<el-input v-model="article.intro" autocomplete="off" style="width:260px;float:left"
						readonly></el-input>
				</el-form-item>
				<el-form-item label="证明材料">
					<div style="text-align: left;">
						<img :src="item" class="report-pic" v-for="item in article.pic" :key="item"
							@click="onViewPic(item)" />
					</div>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="success" @click="onCancel(article.news_id)">取消</el-button>
				<el-button type="danger" @click="onFreeze(article.news_id)">冻结</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import {
		articleList,
		attrList,
		tagList,
		articleDetail,
		articleExamine,
		report,
		freeze
	} from '@/api/adminApi.js'
	export default {
		data() {
			return {
				articles: [], // 文章列表
				currpage: 1, // 初始页
				pagesize: 14, // 每页显示条目个数
				CountNum: 0, // 总条数
				loading: false, //加载框
				contentLoading: false, //内容加载框
				dialogFormVisible: false, // 文章审核弹窗
				Refuse: false, // 拒绝原因弹窗
				cause: '', // 拒绝原因
				article: {
					ID: '',
					Title: '',
					Author: '',
					InsertTime: ''
				},
				content: '', // 文章内容
				category: [], // 文章类别
				Tag: [], // 属性
				Tagoptions: [], // 标签数组
				categoryOptions: [], // 类别数组
				categorytitle: {}, // 标签的Name
				Tagtitle: [] // 属性的Name
			}
		},
		mounted() {
			this.ArticleList(1) // 获取文章列表
		},
		methods: {
			onViewPic(url) {
				window.open(url)
			},
			onFreeze(id) {
				const data = {
					ID: id,
					status: 3
				}
				freeze(data).then(res => {
					if (res.status.code == 1) {
						this.$message({
							message: '冻结成功',
							type: 'success'
						})
						this.category = []
						this.Tag = []
						this.dialogFormVisible = false
						this.ArticleList(this.currpage) // 成功通过后刷新视图
					} else {
						this.$message.error(res.status.msg)
					}
				})
			},
			onCancel(id) {
				const data = {
					ID: id,
					status: 5
				}
				freeze(data).then(res => {
					if (res.status.code == 1) {
						this.$message({
							message: '取消成功',
							type: 'success'
						})
						this.category = []
						this.Tag = []
						this.dialogFormVisible = false
						this.ArticleList(this.currpage) // 成功通过后刷新视图
					} else {
						this.$message.error(res.status.msg)
					}
				})
			},
			ArticleList(currpage) { // 获取文章列表
				this.loading = true
				const data = {
					page: currpage, // 当前页数
					limit: this.pagesize // 每页条数
				}
				report(data).then(res => {
					this.articles = res.data.list // 待审核文章列表
					this.CountNum = parseInt(res.data.CountNum) // 总条数
					this.loading = false
				}).catch(err => {
					console.log(err)
				})
			},
			GetTagInfo() { // 获取标签列表
				tagList().then(res => {
					this.categoryOptions = res.data // 标签
				}).catch(err => {
					console.log(err)
				})
			},
			GetAttributeInfo() { // 获取属性列表
				attrList().then(res => {
					this.Tagoptions = res.data // 属性
				}).catch(err => {
					console.log(err)
				})
			},
			handleEdit(index, row) { // 打开文章弹窗
				this.dialogFormVisible = true
				this.article.InsertTime.substring(0, 10)
				this.article = Object.assign({}, row)
				this.acquireArticle(row.ID)
				this.GetTagInfo() // 获取文章分类
				this.GetAttributeInfo() // 获取属性列表
				// console.log(row)
			},
			through(article) { // 文章审核：通过
				const newArr = this.category.join('-')
				const newTag = this.Tag.join('-')
				const data = {
					ID: article.ID, // 文章ID
					tagId: newArr, // 标签信息
					attrId: newTag, // 属性信息
					status: 1 // 文章状态 ( 1已审核 2已拒绝 )
				}
				if (this.category && this.Tag != '') {
					articleExamine(data).then(res => {
						if (res.status.code == 1) {
							this.$message({
								message: '已通过',
								type: 'success'
							})
							this.category = []
							this.Tag = []
							this.dialogFormVisible = false
							this.ArticleList(this.currpage) // 成功通过后刷新视图
						} else {
							this.$message.error(res.status.msg)
						}
					}).catch(err => {
						console.log(err)
					})
				} else {
					this.$message({
						message: '请补充标签或属性',
						type: 'warning'
					})
				}
			},
			repulse(article) { // 文章审核：拒绝
				this.dialogFormVisible = false // 关闭文章弹窗
				this.Refuse = true // 打开拒绝原因弹窗
			},
			Submit(article) { // 提交拒绝原因
				const data = {
					ID: article.ID,
					status: 2,
					reason: this.cause
				}
				if (this.cause == '') {
					this.$message('请输入拒绝原因')
				} else {
					articleExamine(data).then(res => {
						if (res.status.code == 1) {
							this.$message({
								message: '已拒绝',
								type: 'success'
							})
							this.Refuse = false // 关闭弹窗
							this.cause = '' // 清空输入框
							this.ArticleList(this.currpage) // 成功通过后刷新视图
						} else {
							this.$message.error(res.status.msg)
						}
					}).catch(err => {
						console.log(err)
					})
				}
			},
			acquireArticle(datas) { // 获取文章内容
				this.contentLoading = true
				const data = {
					ID: datas
				}
				articleDetail(data).then(res => {
					this.content = res.data.ArticleContent // 文章内容
					this.contentLoading = false
				}).catch(err => {
					console.log(err)
				})
			},
			handleCurrentChange(val) { // 分页
				this.ArticleList(val)
				this.currpage = val
			}
		}
	}
</script>
<style lang="scss" scoped>
	.article-box {
		width: 100%;

		.el-pagination {
			margin-top: 20px;
		}

		.quill-editor {
			height: 300px;
			margin-bottom: 20px;
		}
	}

	.wordBox {
		border: 1px solid #cccccc;
	}

	.el-tag+.el-tag {
		margin-left: 10px;
	}

	.button-new-tag {
		margin-left: 10px;
		height: 32px;
		line-height: 30px;
		padding-top: 0;
		padding-bottom: 0;
	}

	.input-new-tag {
		width: 90px;
		margin-left: 10px;
		vertical-align: bottom;
	}

	.report-pic {
		width: 100px;
		height: 100px;
		margin-right: 10px;
	}
</style>